import React from 'react'
import { Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@theme-ui/mdx'
import { Button, Box } from 'theme-ui'
import components from '@components/Mdx'

const styles = {
    center: {
        display: 'flex',
        justifyContent: 'center',
        my: 2,
    },
}
export const PostBody = ({ body, download1, download2, download3, download4 }) => {
  return (
    <MDXProvider components={components}>
      <MDXRenderer>{body}</MDXRenderer>
        {
            download1 && (
                <Box sx={styles.center}>
                    <Button variant='primary' as={Link} to={download1}>
                        Download 1
                    </Button>
                </Box>
            )
        }
        {
            download2 && (
                <Box sx={styles.center}>
                    <Button variant='primary' as={Link} to={download2}>
                        Download 2
                    </Button>
                </Box>
            )
        }
        {
            download3 && (
                <Box sx={styles.center}>
                    <Button variant='primary' as={Link} to={download3}>
                        Download 3
                    </Button>
                </Box>
            )
        }
        {
            download4 && (
                <Box sx={styles.center}>
                    <Button variant='primary' as={Link} to={download4}>
                        Download 4
                    </Button>
                </Box>
            )
        }
    </MDXProvider>
  )
}
